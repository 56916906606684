import { gsap } from "gsap";
import Lenis from "@studio-freight/lenis";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import IntroText from "./components/IntroText";
import StoriesSlider from "./components/StoriesSlider";
import TextImage from "./components/TextImage";

const lenis = new Lenis({
    lerp: 0.05,
});

const scrollLinkEls = document.querySelectorAll(".js-lenis-scroll");

class App {
    constructor() {
        this.initIntroText();
        this.initTextImage();
        this.initStoriesSlider();
        this.initCopyClipboard();

        lenis.on("scroll", ScrollTrigger.update);

        gsap.ticker.add(time => {
            lenis.raf(time * 1000);
        });

        gsap.ticker.lagSmoothing(0);

        scrollLinkEls.forEach(el => {
            el.addEventListener("click", e => {
                const link = e.target.closest("a");
                e.preventDefault();
                lenis.scrollTo(link.dataset.anchor, {
                    lock: true,
                });
            });
        });
    }

    initIntroText() {
        const introEl = document.querySelector(".js-intro-text");

        new IntroText({
            el: introEl,
        });
    }

    initTextImage() {
        const textImageEls = document.querySelectorAll(".js-text-image");

        textImageEls.forEach(el => {
            new TextImage({
                el: el,
            });
        });
    }

    initStoriesSlider() {
        const sliderEl = document.querySelector(".js-stories-slider");

        new StoriesSlider({
            el: sliderEl,
        });
    }

    initCopyClipboard() {
        const clipboardEl = document.querySelector(".js-clipboard");

        if (clipboardEl) {
            const email = clipboardEl.getAttribute("href").replace("mailto:", "");

            clipboardEl.addEventListener("click", event => {
                event.preventDefault();

                navigator.clipboard
                    .writeText(email)
                    .then(() => {
                        clipboardEl.classList.add("clicked");

                        // Remove the message after 2 seconds
                        setTimeout(() => {
                            clipboardEl.classList.remove("clicked");
                        }, 2000);
                    })
                    .catch(err => {
                        console.error("Could not copy email:", err);
                    });
            });
        }
    }
}

const app = new App();
