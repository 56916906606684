import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class IntroText {
    constructor(args = {}) {
        this.introEl = args.el || null;
        if (!this.introEl) return;

        this.sectionEl = this.introEl.closest(".section");
        this.parentEl = this.introEl.parentNode;
        this.linesEls = this.introEl.querySelectorAll(".intro-text__line");

        this.headerItemEls = document.querySelectorAll(".header__item");

        this.initialize();
    }

    initialize() {
        gsap.to(this.linesEls, {
            opacity: 0.2,
            stagger: {
                each: 0.2,
            },
            onComplete: () => {
                this.parentEl.classList.add("animated");
                gsap.to(this.headerItemEls, {
                    y: 0,
                    opacity: 1,
                    ease: "power3.inOut",
                    duration: 1,
                    stagger: {
                        amount: 0.125,
                    },
                });
            },
        });

        let linesAnim = gsap.to(this.linesEls, {
            opacity: function (index, target, targets) {
                var totalItems = targets.length;
                var step = (1 - 0.25) / (totalItems - 1);

                if (index === totalItems - 1) {
                    return 0.25;
                } else {
                    return 1 - index * step;
                }
            },
            stagger: {
                each: 0.2,
            },
            paused: true,
        });

        ScrollTrigger.create({
            trigger: this.sectionEl,
            pin: true,
            start: "50% 50%",
            end: "bottom+=100% center",
            endTrigger: this.sectionEl,
            scrub: 2,
            onUpdate: self => {
                if (self.direction === 1 && self.progress > linesAnim.progress()) {
                    linesAnim.progress(self.progress);
                }
            },
        });
    }
}
