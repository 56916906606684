import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class IntroText {
    constructor(args = {}) {
        this.introEl = args.el || null;
        if (!this.introEl) return;

        this.sectionEl = this.introEl.closest(".section");
        this.parentEl = this.introEl.parentNode;
        this.linesEls = Array.from(this.introEl.querySelectorAll(".intro-text__line"));
        this.headerItemEls = document.querySelectorAll(".header__item");

        this.initialize();
    }

    initialize() {
        // Set initial state
        gsap.set(this.linesEls, { opacity: 0 });
        gsap.set(this.headerItemEls, { opacity: 0, yPercent: -100 });

        // Line-by-line staggered opacity animation
        const totalLines = this.linesEls.length;
        const linesAnim = gsap.to(this.linesEls, {
            opacity: (index) => 0.4 + (index / (totalLines - 1)) * 0.6,
            stagger: 0.2,
            duration: 1,
            ease: "power3.inOut",
            paused: true,
            onComplete: () => {
                gsap.to(this.headerItemEls, {
                    yPercent: 0,
                    opacity: 1,
                    ease: "power3.inOut",
                    duration: 1,
                    onStart: () => this.parentEl.classList.add("animated"),
                });
            }
        });

        // ScrollTrigger setup
        ScrollTrigger.create({
            trigger: this.sectionEl,
            start: "top 75%",
            end: "bottom 50%",
            scrub: 1,
            onEnter: () => linesAnim.play(),
            onLeaveBack: () => linesAnim.reverse(),
        });
    }
}