import Swiper from "swiper";
import { Navigation } from "swiper/modules";

import "swiper/css";

export default class StoriesSlider {
    constructor(args = {}) {
        this.sliderEl = args.el || null;
        if (!this.sliderEl) return;

        this.initialize();
    }

    initialize() {
        new Swiper(this.sliderEl, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 48,
            speed: 900,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                992: {
                    slidesPerView: 2,
                    spaceBetween: 96,
                },
            },
        });
    }
}
