import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class TextImage {
    constructor(args = {}) {
        this.textImageEl = args.el || null;
        if (!this.textImageEl) return;

        this.sectionEl = this.textImageEl.closest(".section");

        this.initialize();
    }

    initialize() {
        const backgroundEl = this.sectionEl.querySelector(".section__background");
        const innerEl = this.sectionEl.querySelector(".section__inner");

        const backgroundHeight = backgroundEl.offsetHeight;
        const textHeight = this.textImageEl.offsetHeight / 2;

        const centerOffset = (backgroundHeight - window.innerHeight) / 2;
        const yOffset = (backgroundHeight - textHeight) / 2 + textHeight * 1.5;

        gsap.to(innerEl, {
            y: -yOffset,
            ease: "none",
            scrollTrigger: {
                trigger: this.sectionEl,
                pin: true,
                pinType: "transform",
                start: `top top-=${centerOffset}`, 
                end: `+=${backgroundHeight - textHeight}`,
                scrub: 1.5,
            },
        });
    }
}
