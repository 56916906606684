import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class TextImage {
    constructor(args = {}) {
        this.textImageEl = args.el || null;
        if (!this.textImageEl) return;

        this.sectionEl = this.textImageEl.closest(".section");

        this.initialize();
    }

    initialize() {
        gsap.to(this.sectionEl, {
            '--blur-value': '20px',
            '--text-opacity': '100%',
            scrollTrigger: {
                trigger: this.sectionEl,
                start: "top center",
                end: "bottom center",
                scrub: true,
                once: true
            },
        });
    }
}
